import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
import { Container, Row, Col, Button } from 'reactstrap'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebook , faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../../styles/product/fishcurry.css'

export default function ChiliPowder() {
    return (
        <div>
            <Layout>
                {/* For page head */}
                <Helmet>
                    <title>Chili Powder - Maker Recipe</title>
                </Helmet>

                <div className="fishCurry-firstLayer">
                    <Container>
                        <Row>
                            <Col lg="4" className="backButton-column">
                                <div className="backButton-container">
                                    <Button>
                                        <a href="/products-tumeric-chili">Back</a>
                                    </Button>
                                </div>
                            </Col>
                            <Col lg="4" className="curryProduct-column">
                                <StaticImage 
                                    src="../../images/tumericchiliproducts/Serbuk_Cili_100gm.png" 
                                    alt="Chili Product 1"
                                    width={700}
                                    height={700} />
                            </Col>
                            <Col lg="4" className="curryProductDetail-column">
                                <div className="productDetail">
                                    <h2>Savoury Chili Powder</h2>
                                    <ul>
                                        <li>
                                            Our heritage and expertise in spice coupled with the use of
                                            premium ingredients has paved the way for the perfect home-cooked curry.
                                        </li>
                                        <li>Can be cooked without coconut milk</li>
                                        <li>Great taste and perfect dish to serve</li>
                                        <li>A 20 gram packet is suitable for 4 pax</li>
                                    </ul>
                                </div>
                                <div className="packagingDetail">
                                    <h4>Packaging</h4>
                                    <ul>
                                        <li>20 gram</li>
                                        <li>100 gram</li>
                                    </ul>
                                </div>
                                <div className="shelfLifeDetail">
                                    <h4>Shelf Life</h4>
                                    <ul>
                                        <li>18 Months</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="fishCurry-secondLayer">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="recipe-container">
                                    <div className="recipe-Title">
                                        <h4>Cooking Method</h4>
                                    </div>
                                    <div className="recipe-list">
                                        <ol>
                                            <li>Heat oil in pan</li>
                                            <li>
                                                Sauté red onion, garlic, ginger, mixed herbs, 
                                                tomato and curry leaves until fragrant and yellowish.
                                            </li>
                                            <li>
                                                Add in the FISH SAVOURY CURRY paste mixture with grinded shallots, 
                                                garlic and ginger. Sauté for 3 – 4 minutes until oil separates, using 
                                                low heat.
                                            </li>
                                            <li>
                                                After that, add mackerel, water, tamarind, lady’s finger and 
                                                red chili. Leave it for 8 – 9 minutes over medium heat until 
                                                boiling.
                                            </li>
                                            <li>
                                                Add Coconut Milk and Fine Salt. Leave on for 4 – 5 minutes. Ready 
                                                for serving
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                
                                <div className="recipeSocialMedia-container">
                                    <div className="productFacebookButton">
                                        <Button>
                                            <a href="https://www.facebook.com/MAKER-Recipe-101510671983618/">
                                                <FontAwesomeIcon icon={faFacebook} className="footerIcon" />
                                                {" "}Facebook
                                            </a>
                                        </Button>
                                    </div>
                                    <div className="productInstagramButton">
                                        <Button>
                                            <a href="https://www.instagram.com/maker.recipe/?hl=en">
                                                <FontAwesomeIcon icon={faInstagram} className="footerIcon" />
                                                {" "}Instagram
                                            </a>
                                        </Button>
                                    </div>
                                    <div className="productWhatsappButton">
                                        <Button>
                                            <a href="https://www.instagram.com/maker.recipe/?hl=en">
                                                <FontAwesomeIcon icon={faWhatsapp} className="footerIcon" />
                                                {" "}Whatsapp
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        </div>
    )
}
